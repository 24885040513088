.mb-2 input, .mb-2 select{
    color: #222;
    background-color: #fff;
    box-shadow: 1px 3px 10px 1px rgba(0, 0, 0, 0.200);
    width:max( 10vw,200px);
    font-size: 13px;
    font-weight: 400;
    border: none;
    border-radius: 15px;
    padding: 5px 20px;
    outline: none;
}

form {
    display: inline-block;
    margin: 0 auto;
    margin-top: 5%;
}

.mb-2{
    align-items: center;
    margin-bottom: 1.5rem;
}

.mb-2 label {
    margin-right: 1rem;
    white-space: nowrap;
    display: inline-block;
    width: 120px; 
    text-align:left; 
}

.mb-2 {
    flex: 1;
    max-width: 300px;
    display: flex;
    align-items: center;
    margin-bottom:3px ;
    max-width: 100%;
}
.form-button button{
    margin: 10px;
}