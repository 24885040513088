.content-area {
    background-color: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
}

.table {
    border-collapse: collapse;
    width: 100%;
}

.table th, .table td {
    text-align: left;
    padding: 12px;
}

.table th {
    background-color: #343a40;
    color: white;
}

.table-dark {
    background-color: #2c2c2c;
}

.table-hover tbody tr:hover {
    background-color: #3c3c3c;
}

.input-group .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}


/* Customers.css */

.content-area {
    padding: 15px;
}

.table-responsive {
    overflow-x: auto;
}

.table th,
.table td {
    white-space: nowrap;
}

.btnAdd{
    background-color: #6de56d;
}
.btnAdd:hover{
    background-color: #a0ffa0;
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
  }
  