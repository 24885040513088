.scroll-to-top-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #007bff;
    color: white;
    font-size: 24px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .scroll-to-top-btn:hover {
    background-color: #0056b3;
    transform: scale(1.1);
  }
  
  .scroll-to-top-btn:active {
    transform: scale(0.9);
  }
  