.content-area {
    background-color: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
}

.table {
    border-collapse: collapse;
    width: 100%;
}

.table th, .table td {
    text-align: left;
    padding: 12px;
}

.table th {
    background-color: #343a40;
    color: white;
}

.table-dark {
    background-color: #2c2c2c;
}

.table-hover tbody tr:hover {
    background-color: #3c3c3c;
}

.input-group .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}


/* Customers.css */

.content-area {
    padding: 15px;
}

.table-responsive {
    overflow-x: auto;
}

.table th,
.table td {
    white-space: nowrap;
}

.dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
}

.dropdown-menu input[type="checkbox"] {
    margin-right: 10px;
}

.btn-group {
    display: flex;
    align-items: center;
}

.btn-group .dropdown-menu {
    min-width: 200px;
}

.btnToggle{
    border: 1px solid #3c3c3c;
    background-color: #3c3c3c;
    color: white;
    transition: 0.5s;
}

.btnToggle:hover{
    border: 1px solid #3c3c3c;
    background-color:white;
    color: #3c3c3c;
}
.mb-2 input, .mb-2 select{
    color: #222;
    background-color: #fff;
    box-shadow: 1px 3px 10px 1px rgba(0, 0, 0, 0.200);
    width:max( 10vw,200px);
    font-size: 13px;
    font-weight: 400;
    border: none;
    border-radius: 15px;
    padding: 5px 20px;
    outline: none;
}

.mb-2 label {
    margin-right: 1rem;
    white-space: nowrap;
    display: inline-block;
    width: 120px; 
    text-align:left;
    font-size: 13px; 
}

.small-table {
    font-size: 0.80rem; 
  }
  
  .small-table th,
  .small-table td {
    padding: 0.1rem; /* Adjust padding */
  }
  